import { SxProps, colors } from '@mui/material';

const fab: SxProps = {
  position: 'fixed',
  zIndex: 500,
  top: '16vh',
  borderRadius: 0,
  backgroundColor: colors.red[100],
  color: colors.red[900],
};

const fabLeft: SxProps = {
  left: 0,
  borderTopRightRadius: 6,
  borderBottomRightRadius: 6,
  borderRadius: 0,
};

const fabRight: SxProps = {
  right: 0,
  borderTopLeftRadius: 6,
  borderBottomLeftRadius: 6,
  borderRadius: 0,
};

const drawerGhost: SxProps = {
  height: 1,
  position: 'fixed',
  width: { xs: 0.8, sm: 250 },
  top: 0,
  pointerEvents: 'none',
  zIndex: -1,
};

const drawerPaper: SxProps = {
  backgroundColor: 'oldlace',
  width: { xs: 0.8, sm: 250 },
};

export const css = { fab, fabLeft, fabRight, drawerGhost, drawerPaper };
