import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, TextField } from '@mui/material';
import Preview from 'components/Preview';
import SkinSelectorCard from './SkinSelectorCard';
import StickyBox from 'components/StickyBox';
import Padoru from 'util/padoru';

interface Props {
  padoru: Padoru;
  padoruName: string;
  onChangeSkin: (color: string) => void;
  onChangeName: (name: string) => void;
  isMobile: boolean;
}

export function WorkAreaStyled(props: Props) {
  const { t } = useTranslation();
  const { padoru, padoruName, isMobile, onChangeSkin, onChangeName } = props;
  const [editingName, setPadoruName] = useState<string>('');

  useEffect(() => {
    setPadoruName(padoruName);
  }, [padoruName]);

  const handleBlurName = () => {
    if (editingName) {
      onChangeName(editingName);
    } else {
      alert(t('components.work_area.error_name'));
    }
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Card sx={{ mx: 2, mb: 3 }} id="padoru_name_card">
        <CardContent>
          <TextField
            fullWidth
            id="name_padoru_input"
            label={t('components.work_area.name_input')}
            variant="standard"
            onChange={(e: any) => setPadoruName(e.target.value)}
            onBlur={handleBlurName}
            value={editingName}
            error={!editingName}
          />
        </CardContent>
      </Card>

      {isMobile ? (
        <StickyBox>
          <Card sx={{ mx: 2, p: 1 }}>
            <Preview padoru={padoru} padoruName={padoruName} isMobile={isMobile} />
          </Card>
        </StickyBox>
      ) : (
        <Card sx={{ mx: 2, p: 1 }}>
          <Preview padoru={padoru} padoruName={padoruName} isMobile={false} />
        </Card>
      )}

      <SkinSelectorCard
        sx={{ mt: 3, mx: 2 }}
        skin={padoru.parts.get('head')?.colors[0] ?? ''}
        onChangeSkin={onChangeSkin}
      />
    </Box>
  );
}
