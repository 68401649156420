import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { ROOT, MAKER, DONATE_THANKS } from './paths';
import Container from '@mui/material/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Home from 'pages/Home';
import Main from 'pages/Main';
import Thanks from 'pages/Thanks';
import NotFound from 'pages/NotFound';
import SimpleModal from 'components/Modals';
import ReloadModal from 'components/Modals/ReloadModal';
import css from './app.styles';

const pathApplySizeLoad = [MAKER];
const getOrientation = () => window.screen.orientation.type;
const featureReloadedON = String(process.env.REACT_APP_FEATURE_RELOADED) === 'true';

function App() {
  const pathname = window.location.pathname;
  const isSm = useMediaQuery('(max-width:600px)');
  const [orientation, setOrientation] = useState<string>(getOrientation());
  const [initDone, setInit] = useState<boolean>(false);
  const [openModalResize, setOpenModalRezise] = useState<boolean>(false);
  const updateOrientation = () => setOrientation(getOrientation());

  useEffect(() => {
    setInit(true);
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);

  useEffect(() => {
    if (featureReloadedON && initDone && pathApplySizeLoad.includes(pathname)) {
      setOpenModalRezise(true);
    }
  }, [orientation]);

  return (
    <div>
      <SimpleModal
        open={featureReloadedON && openModalResize}
        onClose={() => setOpenModalRezise(false)}
        btnClose
        sx={{ mt: 2 }}
      >
        <ReloadModal onClose={() => setOpenModalRezise(false)} />
      </SimpleModal>

      <Box id="main_box" sx={css.mainBox}>
        <Stack
          id="main_stack"
          direction="column"
          justifyContent="space-between"
          height="max-content"
        >
          <Header transparent={pathname === '/'} />
          <Container
            id="main_container"
            component="main"
            maxWidth="lg"
            sx={css.mainContainer}
          >
            <Box id="wraper_contenido" position="relative" height="max-content">
              {!isSm && <Box id="blur_bg" sx={css.mainBlur} />}
              <Container maxWidth="lg" sx={css.content}>
                <BrowserRouter>
                  <Routes>
                    <Route path={ROOT} element={<Home />} />
                    <Route path={MAKER} element={<Main />} />
                    <Route path={DONATE_THANKS} element={<Thanks />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
              </Container>
            </Box>
          </Container>
          <Footer />
        </Stack>
      </Box>
    </div>
  );
}

export default App;
