import { useEffect, useState } from 'react';
import { Box, TextField, Avatar, Popover, AvatarTypeMap } from '@mui/material';
import validateHex from 'is-hexcolor';
import { HexColorPicker } from 'react-colorful';
import { contrastColor, normalizeColor } from 'util/helpers';
import PaletteIcon from '@mui/icons-material/PaletteRounded';
import { useTranslation } from 'react-i18next';

type AvatarType = AvatarTypeMap['props']['variant'];

interface Props {
  id: string;
  color: string;
  onClose: (color: string) => void;
  size: number;
  icon?: JSX.Element;
  noBg?: boolean;
  variant?: AvatarType;
}

export function ColorPicker(props: Props) {
  const { t } = useTranslation();
  const {
    id,
    color,
    onClose,
    size,
    icon = <PaletteIcon />,
    noBg,
    variant = 'rounded',
  } = props;

  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [anchorEl, setAnchor] = useState<any>(null);
  const [selectColor, setSelectColor] = useState<string>(color);
  const [iconColor, setIconColor] = useState<string>(contrastColor(color));

  useEffect(() => {
    setSelectColor(color);
  }, [color]);

  const handlePicker = (event: any) => {
    setAnchor(!openPicker ? event.currentTarget : null);
    setOpenPicker(!openPicker);

    if (validateHex(selectColor)) {
      setIconColor(contrastColor(selectColor));
      onClose(selectColor);
    }
  };

  const handleInput = (event: any) => {
    const c = normalizeColor(event.target.value);
    setSelectColor(c);
    if (validateHex(selectColor)) {
      setIconColor(contrastColor(selectColor));
    }
  };

  return (
    <>
      <Avatar
        onClick={handlePicker}
        sx={{
          bgcolor: noBg ? 'transparent' : selectColor,
          color: iconColor,
          height: size,
          width: size,
        }}
        variant={variant}
      >
        {icon}
      </Avatar>

      <Popover
        id={`btn_popover_${id}`}
        open={openPicker}
        anchorEl={anchorEl}
        onClose={handlePicker}
      >
        <Box p={1}>
          <TextField
            id={`id_textfield_${id}`}
            label={t('components.color_picker.label')}
            variant="standard"
            onChange={handleInput}
            value={selectColor}
            sx={{ m: 0.5 }}
          />
          <HexColorPicker color={selectColor} onChange={setSelectColor} />
        </Box>
      </Popover>
    </>
  );
}
