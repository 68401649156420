import { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Drawer,
  Fab,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import NewIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RandomIcon from '@mui/icons-material/CasinoOutlined';
import ToolBarIcon from '@mui/icons-material/ConstructionRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import ShareIcon from '@mui/icons-material/ShareRounded';
import TutorialIcon from '@mui/icons-material/SlowMotionVideoRounded';
// mobiles // FUTHURE
//import AppIcon from '@mui/icons-material/AppShortcutRounded';
//import InstallAppIcon from '@mui/icons-material/InstallMobileRounded';
//import SystemUpdateRoundedIcon from '@mui/icons-material/SystemUpdateRounded';
// modals
import ShareWebSiteModal from 'components/Modals/ShareWebSite';
import ProgressModal from 'components/Modals/ProgressModal';
import DonateModal from 'components/Modals/DonateModal';
import SimpleModal from 'components/Modals';

import { savePadoru, clearLocalPadoru, isRandomFeature } from 'util/padoru-api';
import { makeAndDownload } from 'util/maker';
import { useTranslation } from 'react-i18next';
import { countDownload, sharePage } from 'util/share';
import { css } from './styles';
import tutorial from 'util/tutorial';
import Padoru from 'util/padoru';
import eventManager from 'util/EventEmitter';

interface Props {
  isMobile: boolean;
  openTutorial: (o: boolean) => void;
  onNewPadoru: (o: Padoru) => void;
}

function ToolBar(props: Props) {
  const { t } = useTranslation();
  const { isMobile, openTutorial, onNewPadoru } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [openedModal, setOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);
  const openMenu = () => setShowMenu(true);
  const closeMenu = () => {
    setShowMenu(false);
    eventManager.emmit('open_tutorial', false);
  };

  const newPadoru = () => {
    isRandomFeature(false);
    clearLocalPadoru();
    onNewPadoru(Padoru.zero());
  };

  const randomPadoru = () => {
    isRandomFeature(true);
    clearLocalPadoru();
    onNewPadoru(Padoru.random());
  };

  const downloadPadoru = async () => {
    setModal(<ProgressModal title={t('components.modals.downloading.title')} />);
    openModal();
    countDownload();
    await makeAndDownload();
    closeModal();
  };

  const shareWebSite = () => {
    if (isMobile) {
      sharePage({
        text: t('components.share.web_text'),
        url: '/',
        title: t('components.share.web_title'),
      });
    } else {
      setModal(<ShareWebSiteModal />);
      openModal();
    }
  };

  const handlerDonateBtn = () => {
    setModal(<DonateModal />);
    openModal();
  };

  useEffect(() => {
    tutorial.before(['welcome', 'fin'], [closeMenu]);
    tutorial.before(['toolbar_drawer', 'download_padoru'], [openMenu]);
  }, []);

  return (
    <div>
      <SimpleModal open={openedModal} onClose={closeModal} btnClose>
        <Box>{modal}</Box>
      </SimpleModal>

      <Fab id="toolbar_fab_btn" sx={{ ...css.fab, ...css.fabRight }} onClick={openMenu}>
        <ToolBarIcon />
      </Fab>

      <Box id="toolbar_drawer_ghost" sx={{ ...css.drawerGhost, right: 0 }} />

      <Drawer
        PaperProps={{ sx: css.drawerPaper }}
        anchor="right"
        open={showMenu}
        onClose={closeMenu}
      >
        <Typography variant="h6" sx={{ mx: 2, mt: 2 }}>
          {t('components.toolbar.menu_title')}
        </Typography>

        <Box id="toolbar_drawer" sx={{ overflow: 'auto' }}>
          <List>
            <ListItemButton id="toolbar_new_btn" onClick={newPadoru}>
              <ListItemIcon>
                <NewIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.new')} />
            </ListItemButton>

            <ListItemButton id="toolbar_new_btn" onClick={randomPadoru}>
              <Badge color="secondary" badgeContent="BETA">
                <ListItemIcon>
                  <RandomIcon />
                </ListItemIcon>
                <ListItemText primary={t('components.toolbar.random')} />
              </Badge>
            </ListItemButton>

            <ListItemButton id="toolbar_save_btn" onClick={savePadoru}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.save')} />
            </ListItemButton>

            <ListItemButton id="toolbar_download_btn" onClick={downloadPadoru}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.download')} />
            </ListItemButton>

            <ListItemButton id="toolbar_share_btn" onClick={shareWebSite}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.share_web')} />
            </ListItemButton>

            <ListItemButton id="toolbar_tuto_btn" onClick={() => openTutorial(true)}>
              <ListItemIcon>
                <TutorialIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.tutorial')} />
            </ListItemButton>

            {/* // FUTHURE
            <ListItemButton id="toolbar_tutorial_btn" onClick={() => console.log('abrir modal, mas info notas celu')}>
              <ListItemIcon>
                <InstallAppIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.download_app')} />
            </ListItemButton>
            */}

            <ListItemButton onClick={handlerDonateBtn}>
              <ListItemIcon>
                <FavoriteIcon />
              </ListItemIcon>
              <ListItemText primary={t('components.toolbar.donate')} />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default ToolBar;
