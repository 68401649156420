import { Box, Paper, Typography } from '@mui/material';

interface Props {
  title: string;
  text: string;
  image: string;
}

function PromoCardStyled(props: Props) {
  const { title, text, image } = props;
  return (
    <div>
      <Paper sx={{ p: 2, color: 'white', background: 'none' }} elevation={0}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Typography sx={{ my: 2 }}>{text}</Typography>
        <Box component="img" src={image} boxSizing="border-box" width={1} p={1} />
      </Paper>
    </div>
  );
}

export default PromoCardStyled;
