import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotationRounded';
import SimpleModal from './index';
import ProgressModal from './ProgressModal';

interface Props {
  onClose: () => void;
}

function ReloadModal(props: Props) {
  const { t } = useTranslation();
  const { onClose } = props;
  const [openProgress, setProgress] = useState<boolean>(false);

  const handlerreloadPage = () => {
    setProgress(true);
    window.location.reload();
  };

  return (
    <div>
      <SimpleModal open={openProgress} onClose={() => console.warn('reloading...')}>
        <ProgressModal title="" size={150} />
      </SimpleModal>

      <Typography variant="h6" component="h2">
        <ScreenRotationIcon sx={{ verticalAlign: 'middle' }} />
        <Box sx={{ verticalAlign: 'middle' }} component="span" ml={0.5}>
          {t('components.modals.resize.title')}
        </Box>
      </Typography>
      <Typography my={2} sx={{ whiteSpace: 'pre-line' }}>
        {t('components.modals.resize.content')}
      </Typography>
      <Stack direction="row" justifyContent="flex-end" p={1} spacing={1}>
        <Button variant="text" color="primary" onClick={onClose}>
          {t('components.modals.resize.btn_cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handlerreloadPage}>
          {t('components.modals.resize.btn_reload')}
        </Button>
      </Stack>
    </div>
  );
}

export default ReloadModal;
