import { useEffect, useState } from 'react';
import { Box, CardMedia } from '@mui/material';
import squareBase from 'assets/imgs/base.svg';
import Padoru from 'util/padoru';
import type { PadoruUri, PartUri } from 'interfaces/padoru';
import { saveUriPadoru } from 'util/padoru-api';
import Capa from './Capa';
import { marcaDeAguaProps } from 'CONSTANTS';
import logo_bottom from 'assets/imgs/pmagua.png';

interface Props {
  padoru: Padoru;
  padoruName: string;
  isMobile: boolean;
}

export function PreviewStyled(props: Props) {
  const { padoru, padoruName, isMobile } = props;
  const [parts, setParts] = useState<PartUri[]>([]);

  useEffect(() => {
    const parts1: PartUri[] = Padoru.toPadoruUri(padoru).parts;
    setParts(parts1);
  }, [padoru]);

  useEffect(() => {
    const uriPadoru: PadoruUri = {
      id: padoru.id,
      name: padoruName,
      parts: parts,
    };
    saveUriPadoru(uriPadoru);
  }, [parts]);

  return (
    <Box sx={{ position: 'relative' }} id="preview_wrapper">
      <CardMedia
        id="basse_white"
        title="basse"
        image={squareBase}
        width="100%"
        height="auto"
        component="img"
      />

      <Box>
        {parts.map((part, key) => (
          <Capa key={key} partId={part.uuid} svgUri={part.uri} isMobile={isMobile} />
        ))}

        <Box
          id="marca_agua"
          component="img"
          src={logo_bottom}
          position={'absolute'}
          bottom={0}
          right={0}
          height={marcaDeAguaProps.height}
          width={marcaDeAguaProps.width}
        />
      </Box>
    </Box>
  );
}
