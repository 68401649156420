import Padoru from './padoru';
import { objToMap, uuidName } from './helpers';
import type { KeyPart, PadoruStorage } from 'interfaces/padoru';
import type { Part, PadoruActualStorage, PadoruUri } from 'interfaces/padoru';
import eventManager from './EventEmitter';
import i18n from '../i18n';
const PADORU_ACTUAL = 'cm_current_padoru';
const PADORU_LIST = 'cm_padoru_storage';
const PADORU_URIS = 'cm_padoru_uri';
const PADORU_NOTI = 'cm_padoru_notification';
const PADORU_LIMIT = 20;
const RANDOM_KEY = 'cm_random_key_feature';

export function getPadoru(id: string): Padoru | undefined {
  const list = getPadoruList();
  const pStorage = list.find(p => p.id === id);
  if (pStorage) {
    return Padoru.toPadoru(pStorage);
  }
}

function getIndexPadoru(name: string): number {
  const list = getPadoruList();
  return list.findIndex(p => p.name === name);
}

export function getLocalPadoruActual() {
  const localPadoru = localStorage.getItem(PADORU_ACTUAL);
  if (localPadoru) {
    const padoruObj: PadoruActualStorage = JSON.parse(localPadoru);
    const partsMap = objToMap<KeyPart, Part>(padoruObj.parts);
    return new Padoru(padoruObj.name, partsMap);
  } else {
    const padoruOC = Padoru.OC();
    return Padoru.toPadoru(padoruOC);
  }
}
export function setLocalPadoruActual(padoru: Padoru) {
  set(PADORU_ACTUAL, Padoru.toJson(padoru));
}

export function getPadoruList(): PadoruStorage[] {
  const padoruOC = Padoru.OC();
  try {
    const list = JSON.parse(String(localStorage.getItem(PADORU_LIST)));
    if (Array.isArray(list)) {
      return list.sort((a, b) => Number(b.id) - Number(a.id));
    }
    setList([padoruOC]);
    return [padoruOC];
  } catch (_err) {
    setList([padoruOC]);
    return [padoruOC];
  }
}

export async function savePadoru() {
  isRandomFeature(false);
  const padoru = getLocalPadoruActual();
  const list = getPadoruList();
  padoru.name = padoru.name || uuidName();
  const inx = getIndexPadoru(padoru.name);
  const pStorage = await Padoru.toStorage(padoru);
  if (inx >= 0) {
    list[inx] = pStorage;
    setNewPadoruNotification(false);
  } else if (validateLimit(list)) {
    list.push(pStorage);
    setNewPadoruNotification(true);
  }
  setList(list);
  return list.length;
}

export function deletePadoru(index: number) {
  const list = getPadoruList();
  list.splice(index, 1);
  setList(list);
}

export function saveUriPadoru(padoru: PadoruUri) {
  set(PADORU_URIS, padoru);
}

export function getUriPadoru(): PadoruUri {
  try {
    return JSON.parse(String(localStorage.getItem(PADORU_URIS)));
  } catch (_err) {
    return {} as any;
  }
}

export function clearLocalPadoru() {
  localStorage.removeItem(PADORU_ACTUAL);
  localStorage.removeItem(PADORU_URIS);
}

export function getNewPadoruNotification() {
  return !!localStorage.getItem(PADORU_NOTI);
}

export function isRandomFeature(b?: boolean) {
  if (b === undefined) {
    return localStorage.getItem(RANDOM_KEY) === 'true';
  } else {
    localStorage.setItem(RANDOM_KEY, String(b));
  }
}

function setNewPadoruNotification(n: boolean) {
  eventManager.emmit('new_padoru', { notification: n });
  if (n) {
    set(PADORU_NOTI, { is_new: true });
  } else {
    localStorage.removeItem(PADORU_NOTI);
  }
}

function set(key: string, data: object) {
  localStorage.setItem(key, JSON.stringify(data));
}

function setList(list: PadoruStorage[]) {
  set(PADORU_LIST, list.filter(Boolean));
}

function validateLimit(list: PadoruStorage[]) {
  if (list.length < PADORU_LIMIT) {
    return true;
  }
  const t = i18n.getFixedT(i18n.language);
  alert(t('components.storagebar.limit'));
  return false;
}
