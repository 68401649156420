import type { TFunction } from 'i18next';
import { getPadoruList } from './padoru-api';
import { scrollTo } from './helpers';

type TaskFunction = () => void;
interface TutorialStep {
  selector: string;
  beforeFuncs: TaskFunction[];
  afterFuncs: TaskFunction[];
  content_key: StepKey;
  interaction?: boolean;
}
export interface TourStep {
  selector: string;
  content: string;
  stepInteraction?: boolean;
}

enum StepKeys {
  welcome,
  name,
  preview,
  skin,
  form,
  colors,
  carrousel,
  toolbar_fab,
  toolbar_drawer,
  new_padoru,
  save_padoru,
  download_padoru,
  storage_fab,
  storage_drawer,
  //storage_card,
  storage_actions,
  fin,
}
type StepKey = keyof typeof StepKeys;

//const isMobile = is_mobile({ tablet: true });
const lengthPadoruList = getPadoruList().length;
const selectores: {
  [key in StepKey]: string;
} = {
  welcome: 'non_exists_center_content',
  fin: 'toolbar_fab_btn',
  preview: 'preview_wrapper',
  name: 'padoru_name_card',
  form: 'selector_card_hair',
  skin: 'selector_card_skin',
  colors: 'selector_card_hair_colors',
  carrousel: 'carrousel_hair_middle',
  toolbar_fab: 'toolbar_fab_btn',
  toolbar_drawer: 'toolbar_drawer_ghost',
  new_padoru: 'toolbar_new_btn',
  save_padoru: 'toolbar_save_btn',
  download_padoru: 'toolbar_download_btn',
  storage_fab: 'storage_fab_btn',
  storage_drawer: 'storage_drawer_ghost',
  //storage_card: lengthPadoruList ? 'storage_card_0' : 'storage_drawer_ghost',
  storage_actions: lengthPadoruList ? 'storage_card_0_actions' : 'storage_drawer_ghost',
};

const mapSteps = (arr: StepKey[]): TutorialStep[] => {
  return arr.map(key => ({
    beforeFuncs: [],
    afterFuncs: [],
    selector: `#${selectores[key]}`,
    content_key: key,
  }));
};

const CLIENT_CONSTANT = 'cm_is_constant';
export const isFirstVisit = (): boolean => {
  const is_client = localStorage.getItem(CLIENT_CONSTANT);
  setTimeout(() => {
    localStorage.setItem(CLIENT_CONSTANT, 'true');
  }, 10000);
  return !is_client;
};

class Tutorial {
  steps: TutorialStep[];
  static instance: Tutorial;

  constructor() {
    const keys = Object.keys(StepKeys).filter(v => isNaN(Number(v))) as StepKey[];
    this.steps = mapSteps(keys);
  }

  before(keys: StepKey[], cbs: TaskFunction[] = []) {
    const from = StepKeys[keys[0]],
      to = StepKeys[keys[1]];
    for (let inx = from; inx <= to; inx++) {
      this.steps[inx].beforeFuncs = this.steps[inx].beforeFuncs.concat(cbs);
    }
  }

  after(keys: StepKey[], cbs: TaskFunction[] = []) {
    const from = StepKeys[keys[0]],
      to = StepKeys[keys[1]];
    for (let inx = from; inx <= to; inx++) {
      this.steps[inx].afterFuncs = this.steps[inx].afterFuncs.concat(cbs);
    }
  }

  runTasks(inx: number) {
    if (inx >= 0) {
      this.steps[inx - 1]?.afterFuncs.map(func => func());
    }
    if (inx < this.steps.length) {
      scrollTo(this.steps[inx]?.selector, 'end');
      this.steps[inx]?.beforeFuncs.map(func => func());
    }
  }

  generateSteps(t: TFunction) {
    const steps: TourStep[] = this.steps.map(st => {
      return {
        selector: st.selector,
        content: t(`pages.tutorial.${st.content_key}`),
        stepInteraction: st.interaction,
      };
    });

    return steps;
  }

  static getInstance() {
    if (!Tutorial.instance) {
      Tutorial.instance = new Tutorial();
    }
    return Tutorial.instance;
  }
}

const tutorial = Tutorial.getInstance();
export default tutorial;
