import bgAldea from 'assets/imgs/bg-mobile-night.jpeg';
import type { SxProps } from '@mui/system';

const mainBox: SxProps = {
  height: '100%',
  backgroundImage: `url(${bgAldea})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'repeat',
  backgroundSize: 'auto 100%',
  backgroundAttachment: 'fixed',
};
const mainContainer: SxProps = {
  p: 0,
  m: 'auto',
  position: 'relative',
  minHeight: '90vh',
};
const mainBlur: SxProps = {
  backgroundColor: 'rgb(31 37 74 / 54%)',
  position: 'absolute',
  height: 1,
  width: '100%',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
const content: SxProps = {
  backgroundRepeat: 'repeat',
  backgroundSize: '30% auto',
  py: 1,
  position: 'relative',
  my: { xs: 1, sm: 4 },
};

const css = { mainBlur, mainBox, mainContainer, content };
export default css;
