import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LINK_DRIVE = 'https://cutt.ly/padoru-maker-translate';

function OtherLanguageModal() {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('components.modals.langs.title')}
      </Typography>
      <Typography my={2} sx={{ whiteSpace: 'pre-line' }}>
        {t('components.modals.langs.content')}
      </Typography>
      <Box textAlign="center" mt={2.7}>
        <Link href={LINK_DRIVE} target="_blank">
          {LINK_DRIVE}
        </Link>
      </Box>
    </div>
  );
}

export default OtherLanguageModal;
