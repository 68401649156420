import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DonateButton from 'components/Buttons/DonateBtn';

function DonateModalStyled() {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="h6" component="h2">
        {t('components.modals.donate.title')}
      </Typography>
      <Typography my={2} sx={{ whiteSpace: 'pre-line' }}>
        {t('components.modals.donate.content')}
      </Typography>
      <Box textAlign="center" mt={2.7}>
        <DonateButton image="cards" />
      </Box>
    </div>
  );
}

export default DonateModalStyled;
