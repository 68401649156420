import { random } from 'lodash';
import {
  InxPart,
  ZeroParts,
  DefaultOCParts,
  PadoruThumbnail,
  SkinTones,
} from '../CONSTANTS';
import type {
  PartMap,
  Part,
  PadoruActualStorage,
  PadoruUri,
  PadoruStorage,
} from 'interfaces/padoru';
import Boutique from 'boutique';
import { CustomSvg } from './svg';
import { mapToObject, objToMap, randomHexColor, uuidName } from './helpers';
import { makeThumbnail } from './maker';

class Padoru {
  id: string;
  thumbnail?: string;
  name: string;
  parts: PartMap;
  _v: string;

  constructor(name: string, parts: PartMap) {
    this.id = String(Date.now());
    this.name = name || uuidName();
    this._v = '1.0.0';
    this.parts = parts;
  }

  static async generateIcon(p: Padoru): Promise<string> {
    p.thumbnail = await makeThumbnail();
    return String(p.thumbnail);
  }

  static toJson(p: Padoru): PadoruActualStorage {
    const partsObj = mapToObject(p.parts);
    return {
      id: p.id,
      name: p.name,
      parts: partsObj,
    };
  }

  static toPadoru(storage: PadoruStorage): Padoru {
    const partsMap: PartMap = objToMap(storage.parts);
    const p = new Padoru(storage.name, partsMap);
    p.id = storage.id;
    p.thumbnail = storage.thumbnail;
    return p;
  }

  static async toStorage(p: Padoru): Promise<PadoruStorage> {
    const thumbnail = await Padoru.generateIcon(p);
    const pJson = Padoru.toJson(p);
    return { ...pJson, thumbnail };
  }

  static toPadoruUri(p: Padoru): PadoruUri {
    const parts = Array.from(p.parts.values())
      .map((p: Part) => {
        try {
          const jsvg = Boutique.get(p.key).get(p.uuid);
          const zInx = (InxPart[p.key] * 10 + (jsvg.zInx || 0)) * (jsvg.multiply ?? 1);
          //console.log({k: p.key, m: jsvg.multiply,ink: InxPart[p.key],zinx: jsvg.zInx, fzinx: zInx, cs: p.colors})
          const uri = new CustomSvg(jsvg, p.colors).dataUrl();
          return { zInx, uri, uuid: p.uuid };
        } catch (e) {
          console.error(e);
          return false as any;
        }
      })
      .filter(Boolean)
      .sort((a, b) => a.zInx - b.zInx);

    return { id: p.id, name: p.name, parts: parts };
  }

  //static toV2(padoruV1: Padoru) {}

  static OC(): PadoruStorage {
    return {
      ...Padoru.toJson(new Padoru('Padoru', new Map(DefaultOCParts))),
      thumbnail: PadoruThumbnail,
    };
  }

  static zero(): Padoru {
    return new Padoru(uuidName(), new Map(ZeroParts));
  }

  static random(): Padoru {
    const dontColorKeys = ['head', 'ears'];
    const parts: PartMap = new Map(
      Array.from(ZeroParts.values()).map((p: Part) => {
        const rp = Object.assign({}, p);
        const arr = Boutique.getArray(rp.key);
        const part = arr[random(arr.length - 1)];
        rp.uuid = part.uuid;
        if (!dontColorKeys.includes(rp.key)) {
          rp.colors = p.colors.map(() => randomHexColor());
        } else {
          rp.colors = p.colors.map(() => SkinTones[random(4)]);
        }
        return [rp.key, rp];
      }),
    );
    return new Padoru(uuidName(), parts);
  }
}

export default Padoru;
