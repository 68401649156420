import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTour from 'reactour';
import { Button, colors } from '@mui/material';
import tutorial from 'util/tutorial';
import eventManager from 'util/EventEmitter';
import type { TourStep } from 'util/tutorial';

interface Props {
  open: boolean;
  isFirstVisit: boolean;
  onClose: () => void;
}

function TourStyled(props: Props) {
  const { t } = useTranslation();
  const { open, onClose, isFirstVisit } = props;
  const [steps, setSteps] = useState<TourStep[]>([]);

  useEffect(() => {
    setSteps(tutorial.generateSteps(t));
  }, [t]);

  useEffect(() => {
    eventManager.emmit('open_tutorial', open);
  }, [open]);

  const afterOpen = () => {
    window.scrollTo(0, 0);
    window.scroll(0, 0);
    setSteps(tutorial.generateSteps(t));
    tutorial.runTasks(0);
  };

  const onChangeStep = (n: number) => {
    tutorial.runTasks(n);
    eventManager.emmit('open_tutorial', open);
  };

  return (
    <div id="tutorial_wrapper">
      <ReactTour
        isOpen={open}
        onRequestClose={onClose}
        steps={steps}
        onAfterOpen={afterOpen}
        getCurrentStep={onChangeStep}
        closeWithMask={false}
        showNavigation={!isFirstVisit}
        showNavigationNumber={false}
        disableInteraction
        disableFocusLock
        accentColor={colors.blue[500]}
        startAt={0}
        maskSpace={1}
        rounded={5}
        lastStepNextButton={
          <Button onClick={onClose} variant="contained" sx={{ minWidth: 'max-content' }}>
            {t('pages.tutorial.okey')}
          </Button>
        }
      />
    </div>
  );
}

export default TourStyled;
