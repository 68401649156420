import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, Fab, Typography, Tooltip, Badge, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import StorageIcon from '@mui/icons-material/Inventory2Rounded';
import Padoru from 'util/padoru';
import { deletePadoru, getPadoruList } from 'util/padoru-api';
import { makeAndDownload } from 'util/maker';
import { countDownload, shareImage } from 'util/share';
import { css } from './styles';
import StorageCard from 'components/StorageCard';
import Modal from 'components/Modals';
import eventManager from 'util/EventEmitter';
import tutorial from 'util/tutorial';
import type { PadoruStorage } from 'interfaces/padoru';

interface Props {
  onRestorePadoru: (p: Padoru) => void;
  isMobile: boolean;
}

function StorageBar(props: Props) {
  const { t } = useTranslation();
  const { onRestorePadoru, isMobile } = props;

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [noti, setNoti] = useState<boolean>(false);
  const [padoruList, setPadoruList] = useState<PadoruStorage[]>([]);

  const closeMenu = () => {
    setShowMenu(false);
    setShowInfo(false);
    eventManager.emmit('open_tutorial', false);
  };
  const openMenu = (): void => {
    setNoti(false);
    setShowMenu(true);
  };

  useEffect(() => {
    if (showMenu) {
      const list = getPadoruList();
      setPadoruList(list);
    }
  }, [showMenu]);

  useEffect(() => {
    eventManager.subscribe('new_padoru', (data: any) => setNoti(data.notification));

    tutorial.before(['welcome', 'fin'], [closeMenu]);
    tutorial.before(['storage_drawer', 'storage_actions'], [openMenu]);
  }, []);

  const onDownloadPadoru = async (pStorage: PadoruStorage) => {
    const padoru = Padoru.toPadoru(pStorage);
    const padoruUri = Padoru.toPadoruUri(padoru);
    countDownload();
    await makeAndDownload(padoruUri);
  };

  const onDeletePadoru = (inx: number) => {
    deletePadoru(inx);
    setPadoruList(getPadoruList());
  };

  const onEditPadoru = (pStorage: PadoruStorage) => {
    const padoru = Padoru.toPadoru(pStorage);
    onRestorePadoru(padoru);
    closeMenu();
  };

  const onSharePadoru = (pStorage: PadoruStorage) => {
    const padoru = Padoru.toPadoru(pStorage);
    const padoruUri = Padoru.toPadoruUri(padoru);
    shareImage({
      name: padoruUri.name,
      padoru: padoruUri,
      text: t('components.share.image_text', { name: padoruUri.name }),
    });
  };

  return (
    <div>
      <Fab
        id="storage_fab_btn"
        sx={{ ...css.fab, ...css.fabLeft }}
        onClick={() => openMenu()}
      >
        <Badge color="primary" variant="dot" invisible={!noti}>
          <StorageIcon />
        </Badge>
      </Fab>

      <Modal open={showInfo} btnClose onClose={() => setShowInfo(false)}>
        <InfoIcon />
        <Typography m={1}>{t('components.storagebar.info')}</Typography>
      </Modal>

      <Box id="storage_drawer_ghost" sx={{ ...css.drawerGhost, left: 0 }} />

      <Drawer
        id="storage_drawer"
        PaperProps={{ sx: css.drawerPaper }}
        anchor="left"
        open={showMenu}
        onClose={closeMenu}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" m={2}>
          <Typography variant="h6">{t('components.storagebar.title')}</Typography>
          {isMobile ? (
            <IconButton onClick={() => setShowInfo(true)}>
              <InfoIcon />
            </IconButton>
          ) : (
            <Tooltip placement="right" title={t('components.storagebar.info')!}>
              <InfoIcon />
            </Tooltip>
          )}
        </Box>
        <Box sx={{ overflow: 'auto' }}>
          {padoruList.map((padoruCard, inx) => (
            <StorageCard
              id={`storage_card_${inx}`}
              key={inx}
              onShare={() => onSharePadoru(padoruCard)}
              onDownload={() => onDownloadPadoru(padoruCard)}
              onEdit={() => onEditPadoru(padoruCard)}
              onDelete={() => onDeletePadoru(inx)}
              name={padoruCard.name}
              img={padoruCard.thumbnail}
            />
          ))}
        </Box>
      </Drawer>
    </div>
  );
}

export default StorageBar;
