import { merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Modal, Paper, type SxProps, Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const css: { [key: string]: SxProps } = {
  paper: {
    position: 'relative',
    width: 'max-content',
    maxWidth: '80%',
    heigth: 'max-content',
    maxHeight: '70%',
    overflow: 'auto',
    m: 'auto',
    mt: '15%',
    p: 3,
  },
  closeBtn: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  titlesWithCloseBtn: { '& h1, & h2, & h3, & h4, & h5': { marginRight: 6.4 } },
};

interface Props {
  open: boolean;
  btnClose?: boolean;
  onClose: () => void;
  children?: any;
  sx?: SxProps;
}

function SimpleModalStyled(props: Props) {
  const { t } = useTranslation();
  const { open, btnClose, onClose, children, sx = {} } = props;

  const paperSx = merge(
    !btnClose ? css.paper : merge(css.paper, css.titlesWithCloseBtn),
    sx,
  );

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Paper sx={paperSx}>
        {btnClose && (
          <Box sx={css.closeBtn}>
            <Tooltip title={t('components.modals.close_btn')!}>
              <IconButton onClick={() => onClose()}>
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {children}
      </Paper>
    </Modal>
  );
}

export default SimpleModalStyled;
