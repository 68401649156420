import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transform } from 'lodash';
import Padoru from 'util/padoru';
import { AfectedSkinParts, SchemaForm } from 'CONSTANTS';
import { getLocalPadoruActual, setLocalPadoruActual } from 'util/padoru-api';
import MainView from './MainView';
import { isFirstVisit } from 'util/tutorial';
import eventManager from 'util/EventEmitter';
import type {
  ChangeColorFunction,
  ChangePartFunction,
  SelectedsUuid,
  ISchema,
} from 'interfaces/app';
import type { CustomColor, KeyPart } from 'interfaces/padoru';

export function MainContainer() {
  const { t } = useTranslation();
  const actualFromLocal = getLocalPadoruActual();
  const firstVisit = isFirstVisit();

  const [padoruActual, setPadoruActual] = useState<Padoru>(actualFromLocal);
  const [padoruName, setPadoruName] = useState<string>(actualFromLocal.name);
  const [Schema, setSchema] = useState<ISchema>([]);
  const [openTutorial, setOpenTutorial] = useState<boolean>(firstVisit);
  const [forceReset, setReset] = useState<number>(0);
  const [initUuids, setInitSelectesds] = useState<SelectedsUuid>({});

  useEffect(() => {
    const local = getLocalPadoruActual();
    setPadoruActual(local);
    setPadoruName(local.name);
    const s1: SelectedsUuid = transform(
      Array.from(local.parts.values()),
      (acc: SelectedsUuid, cur) => (acc[cur.key] = cur.uuid),
    );
    setInitSelectesds(s1);
    eventManager.subscribe('open_tutorial', (lock: boolean) => {
      setTimeout(() => (document.body.style.overflowY = lock ? 'hidden' : 'auto'), 250);
    });
  }, []);

  useEffect(() => {
    padoruActual.name = padoruName;
    setLocalPadoruActual(padoruActual);
  }, [padoruActual]);

  useEffect(() => {
    const updated = { ...padoruActual };
    updated.name = padoruName;
    setPadoruActual(updated);
  }, [padoruName]);

  useEffect(() => {
    setSchema(SchemaForm(t));
  }, [t]);

  const onChangePart: ChangePartFunction = useCallback(
    (key_part: KeyPart, custom: CustomColor) => {
      return (uuid: string) => {
        const updated = { ...padoruActual };
        updated.parts.set(key_part, {
          colors: custom,
          key: key_part,
          uuid: uuid,
        });
        setPadoruActual(updated);
        return key_part;
      };
    },
    [Schema, forceReset],
  );

  const onChangeColor: ChangeColorFunction = useCallback(
    (partsKeys: KeyPart[], colorN: number) => {
      return (color: string) => {
        const updated = { ...padoruActual };
        partsKeys.forEach((key: KeyPart) => {
          const updatePart = updated.parts.get(key);
          if (updatePart) {
            updatePart.colors[colorN] = color;
            updated.parts.set(key, updatePart);
          }
        });
        setPadoruActual(updated);
      };
    },
    [Schema, forceReset],
  );

  const onChangeSkin = useMemo(
    () => onChangeColor(AfectedSkinParts, 0),
    [Schema, forceReset],
  );
  const onChangeName = useCallback((name: string) => setPadoruName(name), [padoruActual]);

  const onNewOrRestorePadoru = useCallback(
    (p: Padoru) => {
      setLocalPadoruActual(p);
      setPadoruActual(p);
      setPadoruName(p.name);
      setReset(Date.now());
    },
    [Schema],
  );

  return (
    <MainView
      initUuids={initUuids}
      isFirstVisit={firstVisit}
      padoru={padoruActual}
      padoruName={padoruName}
      onChangePart={onChangePart}
      onChangeColor={onChangeColor}
      onChangeSkin={onChangeSkin}
      onChangeName={onChangeName}
      onRestorePadoru={onNewOrRestorePadoru}
      Schema={Schema}
      openTuto={openTutorial}
      openTutorial={setOpenTutorial}
      forceReset={forceReset}
    />
  );
}
