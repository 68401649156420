import {
  Card,
  IconButton,
  Box,
  CardContent,
  CardActions,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import ShareIcon from '@mui/icons-material/ShareRounded';
import DownloadIcon from '@mui/icons-material/FileDownloadRounded';
import EditIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { canShare } from 'util/share';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  onDelete: () => void;
  onEdit: () => void;
  onShare: () => void;
  onDownload: () => void;
  name: string;
  img: string;
}

export function StorageCardStyled(props: Props) {
  const { t } = useTranslation();
  const { onDelete, onEdit, onShare, onDownload, name, img, id } = props;

  return (
    <Card variant="elevation" sx={{ mx: 2, mb: 2 }} id={id}>
      <CardContent>
        <Typography
          noWrap
          variant="subtitle2"
          sx={{ textOverflow: 'ellipsis', fontWeight: 'bold' }}
        >
          {name}
        </Typography>
      </CardContent>

      <CardMedia
        component="img"
        sx={{ height: 'calc(200px - 4em)' }}
        width={200}
        image={img}
        alt={name}
      />

      <CardActions disableSpacing id={`${id}_actions`}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-around' }}>
          {canShare() && (
            <Tooltip title={t('components.storagebar.share')!}>
              <IconButton onClick={onShare}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('components.storagebar.download')!}>
            <IconButton onClick={onDownload}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('components.storagebar.edit')!}>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('components.storagebar.delete')!}>
            <IconButton onClick={onDelete} color="error">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </Card>
  );
}
