import { useState, useEffect } from 'react';
import svg2png from 'util/svg2png';
import { Avatar, ToggleButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import { isRandomFeature } from 'util/padoru-api';

interface Props {
  svgUri: string;
  onClick: (uuid: string) => void;
  isMobile: boolean;
  uuid: string;
  selected?: boolean;
}

function BtnImg(props: Props) {
  const { svgUri, onClick, uuid, isMobile, selected } = props;
  const [src, setSrc] = useState<string>('');

  const isRandom = isRandomFeature();
  useEffect(() => {
    if (!isMobile && !isRandom) {
      svg2png(svgUri).then(setSrc);
    }
  }, [svgUri]);

  useEffect(() => {
    setSrc(svgUri);
  }, [svgUri]);

  return (
    <ToggleButton
      value={uuid}
      onClick={() => onClick(uuid)}
      sx={{
        p: 0,
        borderStyle: selected ? 'solid' : 'none',
        borderColor: blue[800],
        borderWidth: 2,
      }}
    >
      <Avatar
        sx={{
          bgcolor: blue[100],
          p: 1,
          width: 60,
          height: 60,
        }}
        src={src}
        variant="rounded"
      />
    </ToggleButton>
  );
}

export default BtnImg;
