import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, Typography, Link, SxProps } from '@mui/material';
import { red } from '@mui/material/colors';
import SignLine from './SignLine';
import SimpleModal from 'components/Modals';
import LangModal from 'components/Modals/OtherLanguage';
import DonateModal from 'components/Modals/DonateModal';

const contadorVisitON = String(process.env.REACT_APP_COUNTER_VISITOR) === 'true';
const FooterStyles: SxProps = {
  borderTop: '4px solid white',
  fontSize: '0.75rem',
  backgroundColor: red[100],
};

export function FooterStyled() {
  const { t } = useTranslation();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const openModalLang = () => {
    setModal(<LangModal />);
    openModal();
  };
  const openModalDonate = () => {
    setModal(<DonateModal />);
    openModal();
  };

  return (
    <Box id="footer" sx={FooterStyles} component="footer">
      <SimpleModal open={isOpenModal} onClose={closeModal} btnClose>
        {modal}
      </SimpleModal>

      <Container maxWidth="lg" id="banner_footer_container">
        <Grid container spacing={2} columns={2} id="banner_footer" pb={1}>
          <Grid item xs={2} sm={2} id="banner_footer_1">
            <Typography variant="inherit" mt={2} align="center">
              {t('components.footer.langs_section')}{' '}
              <Link onClick={openModalLang} component="button">
                {t('components.footer.langs_link')}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={2} sm={1} id="banner_footer_2">
            <Box>
              <Typography variant="inherit" mb={2}>
                {t('components.footer.visitors_section')}
              </Typography>
              <Typography variant="inherit" my={0}>
                {t('components.footer.visitors')}
              </Typography>
              {contadorVisitON ? (
                <Box id="contador_wrapper" width="max-content" m="auto">
                  <div id="sfc5424q1ma69bmae2ayk6ax1xh74f1zzb9">
                    <img
                      src="https://counter5.optistats.ovh/private/contadorvisitasgratis.php?c=5424q1ma69bmae2ayk6ax1xh74f1zzb9"
                      title="contador_de_visitas"
                      alt="contador_de_visitas"
                    />
                  </div>
                </Box>
              ) : (
                <Typography variant="inherit" my={0} color="#06157b">
                  {t('components.footer.visitors_disabled')}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={2} sm={1} id="banner_footer_1">
            <Typography variant="inherit" mb={2}>
              {t('components.footer.donate_section')}
            </Typography>
            <Box textAlign="center" py={1.3}>
              <Link
                onClick={openModalDonate}
                component="img"
                height="2.5em"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                alt="Donate"
                sx={{ cursor: 'pointer' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <SignLine />
    </Box>
  );
}
