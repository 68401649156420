import ReactDOMServer from 'react-dom/server';
import svgToDataURL from 'svg-to-dataurl';
import { Colors } from '../CONSTANTS';
import type { CustomColor } from 'interfaces/padoru';
import type { JSvg, SvgPiece } from 'interfaces/svg';

type Color = keyof typeof Colors;
const fill = (g: SvgPiece, custom: CustomColor): string => {
  let color: string = Colors[g.color.toUpperCase() as Color] || g.color;
  if (/^color([0-9]+)/.test(g.color.toLowerCase())) {
    const num = Number(g.color.toLowerCase().replace('color', ''));
    color = custom[num];
  }
  return color;
};

const viewBox = (json: JSvg, isIcon: boolean): string => {
  const defaultBox = '0 0 355 355';
  return !isIcon ? defaultBox : json.iconBox || defaultBox;
};

const opacity = (g: SvgPiece): string => {
  return String(g.opacity || 1);
};

export class CustomSvg {
  tag: JSX.Element;
  constructor(json: JSvg, colors: CustomColor, isIcon?: boolean) {
    this.tag = (
      <svg
        id={json.name}
        xmlns="http://www.w3.org/2000/svg"
        width={!!isIcon ? '60' : '355'}
        height={!!isIcon ? '60' : '355'}
        viewBox={viewBox(json, !!isIcon)}
      >
        {json.pieces.map((g: SvgPiece, kg: number) => (
          <g
            id={`${json.name}_${kg}`}
            key={kg}
            opacity={opacity(g)}
            fill={fill(g, colors)}
          >
            {g.paths.map((path: string, kpath: number) => (
              <path id={`${json.name}_${kg}_${kpath}`} key={kpath} d={path}></path>
            ))}
          </g>
        ))}
      </svg>
    );
  }

  svg(): JSX.Element {
    return this.tag;
  }

  renderString(): string {
    return ReactDOMServer.renderToStaticMarkup(this.tag);
  }

  dataUrl(): string {
    const str = ReactDOMServer.renderToStaticMarkup(this.tag);
    return svgToDataURL(str);
  }
}
